"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "doesSourceHaveColor", {
  enumerable: true,
  get: function get() {
    return _utils.doesSourceHaveColor;
  }
});
Object.defineProperty(exports, "getColor", {
  enumerable: true,
  get: function get() {
    return _utils.getColor;
  }
});
Object.defineProperty(exports, "getName", {
  enumerable: true,
  get: function get() {
    return _utils.getName;
  }
});
Object.defineProperty(exports, "isValid", {
  enumerable: true,
  get: function get() {
    return _utils.isValid;
  }
});
Object.defineProperty(exports, "make", {
  enumerable: true,
  get: function get() {
    return _utils.make;
  }
});
var _utils = require("./lib/utils");