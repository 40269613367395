const numberToWord = [
  "صفر",
  "یک",
  "دو",
  "سه",
  "چهار",
  "پنج",
  "شش",
  "هفت",
  "هشت",
  "نه",
  "ده",
  "یازده",
  "دوازده",
];

const contentReducer = (state) => {
  const content = {
    code: codeReducer(state),
    title: titleReducer(state),
    quality: qualityReducer(state),
    pieces: piecesReducer(state),
    sizes: sizesReducer(state),
    price: priceReducer(state),
    colors: colorsReducer(state),
    hashtags: hashtagsReducer(state),
    watermark: watermarkReducer(state),
  };

  return `${content.code.length ? `${content.code}\n` : ""}${
    content.title.length ? `${content.title}\n` : ""
  }${content.quality.length ? `${content.quality}\n` : ""}${
    content.sizes.length ? `${content.sizes}\n` : ""
  }${content.colors.length ? `${content.colors}\n` : ""}${
    content.price.length ? `${content.price}\n` : ""
  }${content.pieces.length ? `${content.pieces}\n` : ""}${
    state.currentPost.isDeliveryNoticeChecked ? "🛵 ارسال به سراسر کشور\n" : ""
  }${
    state.currentPost.isMassSellNoticeChecked ? "🔴 فروش فقط به صورت عمده می باشد.\n" : ""
  }${content.hashtags.length ? `\n${content.hashtags}\n` : ""}${
    content.watermark.length ? `\n${content.watermark}` : ""
  }`;
};

const codeReducer = (state) => {
  return state.currentPost.code && state.currentPost.code.length ? `🔵 کد ${state.currentPost.code}` : "";
};

const piecesReducer = (state) => {
  const pieces = state.currentPost.pieces.split(",").filter((piece) => piece.length);

  return pieces.length
    ? `📦 جین ${pieces
        .map((piece, index) => {
          let del = "، ";

          if (index === pieces.length - 1) {
            del = "";
          }

          if (index === pieces.length - 2) {
            del = " و ";
          }

          return `${piece}${del}`;
        })
        .join("")} تایی`
    : "";
};

const qualityReducer = (state) => {
  return state.currentPost.quality && state.currentPost.quality.length
    ? `🧶 جنس پارچه ${state.currentPost.quality}`
    : "";
};

const titleReducer = (state) => {
  return state.currentPost.sex && state.currentPost.sex.length && state.currentPost.title && state.currentPost.title.length
    ? state.currentPost.sex === "girls"
      ? `🙋‍♀️ ${state.currentPost.title}`
      : `🙋‍♂️ ${state.currentPost.title}`
    : "";
};

const sizesReducer = (state) => {
  const sizes = state.currentPost.sizes.split(",").filter((size) => size.length);

  return sizes.length
    ? `🏷 در${
        sizes.length === 1 && sizes[0].includes(" ")
          ? ""
          : ` ${numberToWord[sizes.length]} سایز`
      } ${sizes
        .map((size, index) => {
          let del = "، ";

          if (index === sizes.length - 1) {
            del = "";
          }

          if (index === sizes.length - 2) {
            del = " و ";
          }

          return `${size}${del}`;
        })
        .join("")}`
    : "";
};

const priceReducer = (state) => {
  const prices = state.currentPost.price.split(",").filter((price) => price.length);

  return prices.length
    ? `${prices
        .map((_price) => {
          const [price, description] = _price.split(":");
          if (!description) {
            return `💰 قیمت ${price}`;
          }

          return `💰 ${price}: ${description}`;
        })
        .join("\n")}`
    : "";
};

const colorsReducer = (state) => {
  return state.currentPost.colors && state.currentPost.colors.length
    ? `🎨 در ${numberToWord[state.currentPost.colors]} رنگ متنوع`
    : "";
};

const hashtagsReducer = (state) => {
  const boysRelatedHashtags = "#لباس_پسرانه #پوشاک #پسرانه #پسرانه";
  const girlsRelatedHashtags = "#لباس_دخترانه #پوشاک_دخترانه #دخترانه";
  const massSellRelatedHashtags =
    "#پوشاک_نانی_پوش #عمده #پوشاک_عمده #لباس_عمده #عمده_فروشی";
  const retailSellRelatedHashtags = "#تینوس #پوشاک_تینوس";
  const basicHashtags =
    "#پوشاک #لباس #لباس_کودک #پوشاک_کودک #پوشاک_بچه #لباس_بچه #لباس_بچگانه #پوشاک_بچگانه";

  return `${state.currentPost.isBasicHashtagsChecked ? basicHashtags : ""}${
    state.currentPost.isRetialSellHashtagsChecked ? " " + retailSellRelatedHashtags : ""
  }${state.currentPost.isMassSellHashtagsChecked ? " " + massSellRelatedHashtags : ""}${
    state.currentPost.isBoyHashtagsChecked ? " " + boysRelatedHashtags : ""
  }${state.currentPost.isGirlHashtagsChecked ? " " + girlsRelatedHashtags : ""}`;
};

const watermarkReducer = (state) => {
  return state.currentPost.watermark && state.currentPost.watermark.length ? state.currentPost.watermark : "";
};

export { contentReducer };
