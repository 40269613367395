"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.devices = void 0;
var devices = {
  x2lg: {
    maxWidth: "100000px",
    minWidth: "1600px"
  },
  xlg: {
    minWidth: "1200px",
    maxWidth: "1599px"
  },
  lg: {
    minWidth: "992px",
    maxWidth: "1199px"
  },
  md: {
    minWidth: "768px",
    maxWidth: "991px"
  },
  sm: {
    minWidth: "576px",
    maxWidth: "767px"
  },
  xs: {
    maxWidth: "576px",
    minWidth: "0"
  }
};
exports.devices = devices;