"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeCssSelectorAndFillWithRuleset = exports.makeCssRuleset = exports.makeCssRuleTitle = exports.makeCssRule = void 0;
var makeCssSelectorAndFillWithRuleset = function makeCssSelectorAndFillWithRuleset(selector, content) {
  return !selector || !selector.length || !content ? content || "" : "".concat(selector, " {").concat(content, "}");
};
exports.makeCssSelectorAndFillWithRuleset = makeCssSelectorAndFillWithRuleset;
var makeCssRuleset = function makeCssRuleset() {
  var jsfCss = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.entries(jsfCss).reduce(function (result, _jsfCss) {
    return result + makeCssRule(makeCssRuleTitle(_jsfCss[0]), _jsfCss[1]);
  }, "");
};
exports.makeCssRuleset = makeCssRuleset;
var makeCssRule = function makeCssRule(title, value) {
  return "".concat(title, ": ").concat(value, ";");
};
exports.makeCssRule = makeCssRule;
var makeCssRuleTitle = function makeCssRuleTitle(jsfCssTitle) {
  return jsfCssTitle.split("").map(function (_char) {
    return _char.toUpperCase() === _char ? "-".concat(_char.toLowerCase()) : _char;
  }).join("");
};
exports.makeCssRuleTitle = makeCssRuleTitle;