"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.make = exports.isValid = exports.getName = exports.getColor = exports.doesSourceHaveColor = void 0;
var prefix = "fa//";
var seperator = "//";
var make = function make(name) {
  var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var colorize = function colorize(name, color) {
    return "".concat(prefix).concat(name).concat(seperator).concat(color);
  };
  if (isValid(name)) {
    return !color ? name : colorize(getName(name), color);
  }
  return !color ? "".concat(prefix).concat(name) : colorize(name, color);
};

/**
 * Checks for validity of a string for being a FA string
 * @param {*} string
 */
exports.make = make;
var isValid = function isValid(string) {
  return string && typeof string === "string" && string.startsWith(prefix) && string.length > prefix.length + 1;
};
exports.isValid = isValid;
var getColor = function getColor(source) {
  if (!source || !isValid(source) || !doesSourceHaveColor(source)) {
    return null;
  }
  var colorStartsAt = source.lastIndexOf(seperator) + seperator.length;
  return source.slice(colorStartsAt);
};

/**
 * Checks if a FA source string is consists of custom color segment
 * @param {*} source
 */
exports.getColor = getColor;
var doesSourceHaveColor = function doesSourceHaveColor(source) {
  return source && isValid(source) && source.lastIndexOf(seperator) > prefix.length + 1;
};

/**
 * Returns the name segment of a FA source string
 * @param {*} source
 */
exports.doesSourceHaveColor = doesSourceHaveColor;
var getName = function getName(source) {
  if (!source || !isValid(source)) {
    return null;
  }
  var nameStartsAt = 0 + prefix.length;
  var nameEndsAt = doesSourceHaveColor(source) ? source.lastIndexOf(seperator) : undefined;
  return source.slice(nameStartsAt, nameEndsAt);
};
exports.getName = getName;