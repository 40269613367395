"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responsiveFontSizeConstants = exports.pixularFontSizeConstants = void 0;
var pixularFontSizeConstants = {
  xs: 11,
  sm: 12,
  md: 14,
  lg: 16,
  xlg: 18,
  x2lg: 20,
  x3lg: 24,
  x4lg: 32
};
exports.pixularFontSizeConstants = pixularFontSizeConstants;
var responsiveFontSizeConstants = {
  xs: 1.2,
  sm: 1.5,
  md: 1.8,
  lg: 2,
  xlg: 2.5,
  x2lg: 3.5,
  x3lg: 5,
  x4lg: 10
};
exports.responsiveFontSizeConstants = responsiveFontSizeConstants;