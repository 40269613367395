import Parse from "./Parse";

class PostParseObject extends Parse.Object {
  constructor() {
    super("Post");
  }

  static async getConverted(object, fetchOptions = {}) {
    return {
      id: object.id,
      code: object.get("code"),
      data: object.get("data"),
    };
  }
}

Parse.Object.registerSubclass("Post", PostParseObject);

export default PostParseObject;
