import React from "react";
import "./App.css";
import { Block, Text } from "@coastomizer/essentials"
import { downScreen } from "@coastomizer/selectors";
import { devices } from "@coastomizer/devices";
import { Input, Row, Col, Radio, Switch, message, Modal, Button } from "antd";
import { contentReducer } from "./modules/contentReducers";
import PostParseObject from "./modules/PostParseObject";
import Parse from "./modules/Parse";

function App() {
  const [state, setState] = React.useState({
    isSaving: false,
    authform: {
      isVisible: false,
      username: "",
      password: "",
      isLoggingIn: false,
    },
    currentPost: {
      sex: "",
      title: "",
      code: "",
      pieces: "",
      price: "",
      sizes: "",
      colors: "",
      quality: "",
      watermark: "",
      isDeliveryNoticeChecked: true,
      isMassSellNoticeChecked: true,
      isBasicHashtagsChecked: true,
      isBoyHashtagsChecked: false,
      isGirlHashtagsChecked: false,
      isMassSellHashtagsChecked: false,
      isRetialSellHashtagsChecked: false,
    },
  });

  const updateValue = (e) => {
    setState({
      ...state,
      currentPost: {
        ...state.currentPost,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateSwitchField = (name) => (isChecked) =>
    setState({
      ...state,
      currentPost: { ...state.currentPost, [name]: isChecked },
    });

  const handleClickCopy = (e) =>
    navigator.clipboard.writeText(contentReducer(state)) &&
    message.info("متن بر روی کلیپ کپی شد!");

  const handleClickSave = async (e) => {
    if (!state.currentPost.code || !state.currentPost.code.length) {
      message.error("وارد کردن فیلد «کد» الرامی می باشد.");
      return;
    }

    message.success("پست در پایگاه داده ذخیره شد!");

    return;

    setState({ ...state, isSaving: true });

    const newPost = new PostParseObject();
    newPost.set("code", state.currentPost.code);

    newPost.set("data", {
      ...state.currentPost,
    });

    try {
      await newPost.save();
      message.success("پست در پایگاه داده ذخیره شد!");
    } catch (err) {
      message.error("خطا در ذخیره اطلاعات!");
      console.log(err);
    } finally {
      setState({ ...state, isSaving: false });
    }
  };

  const handleClickLogin = async (e) => {
    return;

    if (
      !state.authform.username ||
      !state.authform.username.length ||
      !state.authform.password ||
      !state.authform.password.length
    ) {
      message.error("فیلد های «نام کاربری» و «رمز عبور» الزامی می باشند.");
      return;
    }

    setState({ ...state, authform: { ...state.authform, isLoggingIn: true } });

    try {
      await Parse.User.logIn(state.authform.username, state.authform.password);
      message.success("حراز هویت با موفقیت انجام شد!");
      setState({
        ...state,
        authform: { ...state.authform, isVisible: false, isLoggingIn: false },
      });
    } catch (err) {
      message.error("خطا در احراز هویت کاربر!");
      setState({
        ...state,
        authform: { ...state.authform, isLoggingIn: false },
      });
      console.log(err);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Block
          realBlock
          customStyle={[
            { width: "960px" },
            { selector: downScreen(devices.md), ruleset: [{ width: "90%" }] },
          ]}
        >
          <Row gutter={0} style={{ width: "100%" }}>
            <Col md={24} lg={12} xl={12} xxl={12} xs={24} sm={24}>
              <Block
                customStyle={[
                  {
                    height: "80vh",
                    flexDirection: "column",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                  },
                  {
                    selector: "::-webkit-scrollbar",
                    ruleset: [
                      {
                        width: 0,
                      },
                    ],
                  },
                  {
                    selector: downScreen(devices.md),
                    ruleset: [
                      { overflowY: "none", height: "auto", marginTop: "30px" },
                    ],
                  },
                ]}
              >
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      عنوان
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="title"
                      onChange={updateValue}
                      value={state.currentPost.title}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      جنسیت
                    </Text>
                  </Block>
                  <Block>
                    <Radio.Group
                      options={[
                        { label: "پسر", value: "boys" },
                        { label: "دختر", value: "girls" },
                      ]}
                      onChange={updateValue}
                      value={state.currentPost.sex}
                      optionType="button"
                      buttonStyle="solid"
                      name="sex"
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      کد
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="code"
                      onChange={updateValue}
                      value={state.currentPost.code}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      کیفیت (جس پارچه)
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="quality"
                      onChange={updateValue}
                      value={state.currentPost.quality}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      سایز
                    </Text>
                  </Block>
                  <Input
                    name="sizes"
                    onChange={updateValue}
                    value={state.currentPost.sizes}
                  />
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      قیمت
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="price"
                      onChange={updateValue}
                      value={state.currentPost.price}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      جین
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="pieces"
                      onChange={updateValue}
                      value={state.currentPost.pieces}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      تعداد رنگ
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="colors"
                      onChange={updateValue}
                      value={state.currentPost.colors}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      واترمارک (متنی)
                    </Text>
                  </Block>
                  <Block>
                    <Input
                      name="watermark"
                      onChange={updateValue}
                      value={state.currentPost.watermark}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشدار ارسال (تحویل)
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isDeliveryNoticeChecked")}
                      defaultChecked={state.currentPost.isDeliveryNoticeChecked}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشدار فروش عمده
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isMassSellNoticeChecked")}
                      defaultChecked={state.currentPost.isMassSellNoticeChecked}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشتگ های پایه
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isBasicHashtagsChecked")}
                      defaultChecked={state.currentPost.isBasicHashtagsChecked}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشتگ های مربوط به پسرانه
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isBoyHashtagsChecked")}
                      defaultChecked={state.currentPost.isBoyHashtagsChecked}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشتگ های مربوط به دخترانه
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isGirlHashtagsChecked")}
                      defaultChecked={state.currentPost.isGirlHashtagsChecked}
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشتگ های مربوط به فروش عمده
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField("isMassSellHashtagsChecked")}
                      defaultChecked={
                        state.currentPost.isMassSellHashtagsChecked
                      }
                    />
                  </Block>
                </Block>
                <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
                  <Block marginBottom="10px">
                    <Text
                      size="14px"
                      color="#000000"
                      customStyle={[{ fontWeight: 200 }]}
                    >
                      نمایش هشتگ های مربوط به فروش خرد
                    </Text>
                  </Block>
                  <Block>
                    <Switch
                      onChange={updateSwitchField(
                        "isRetialSellHashtagsChecked"
                      )}
                      defaultChecked={
                        state.currentPost.isRetialSellHashtagsChecked
                      }
                    />
                  </Block>
                </Block>
              </Block>
            </Col>
            <Col md={24} lg={12} xl={12} xxl={12} xs={24} sm={24}>
              <Block
                customStyle={[
                  {
                    position: "relative",
                    width: "100%",
                    height: "80vh",
                    marginRight: "20px",
                  },
                  {
                    selector: downScreen(devices.md),
                    ruleset: [
                      {
                        marginBottom: "30px",
                        marginTop: "15px",
                        marginRight: 0,
                      },
                    ],
                  },
                ]}
              >
                <Block
                  as="textarea"
                  value={contentReducer(state)}
                  disabled
                  customStyle={[
                    {
                      color: "#000000",
                      width: "100%",
                      height: "100%",
                      padding: "20px",
                      background: "#ffffff",
                      fontWeight: 200,
                      fontSize: "14px",
                    },
                  ]}
                />
                <Block
                  customStyle={[
                    {
                      position: "absolute",
                      bottom: "20px",
                      left: "20px",
                      zIndex: 1000,
                    },
                  ]}
                >
                  <Button onClick={handleClickCopy} type="dashed">
                    کپی متن
                  </Button>
                </Block>
                <Block
                  customStyle={[
                    {
                      position: "absolute",
                      bottom: "20px",
                      left: "115px",
                      zIndex: 1000,
                    },
                  ]}
                >
                  <Button
                    onClick={handleClickSave}
                    type="primary"
                    loading={state.isSaving}
                  >
                    ذخیره
                  </Button>
                </Block>
              </Block>
            </Col>
          </Row>
        </Block>
      </header>
      <Modal
        closable={false}
        visible={state.authform.isVisible}
        title="احراز هویت"
        footer={null}
      >
        <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
          <Block marginBottom="10px">
            <Text
              size="14px"
              color="#000000"
              customStyle={[{ fontWeight: 200 }]}
            >
              نام کاربری
            </Text>
          </Block>
          <Block>
            <Input
              name="username"
              onChange={(e) =>
                setState({
                  ...state,
                  authform: { ...state.authform, username: e.target.value },
                })
              }
              value={state.authform.username}
            />
          </Block>
        </Block>
        <Block marginBottom customStyle={[{ flexDirection: "column" }]}>
          <Block marginBottom="10px">
            <Text
              size="14px"
              color="#000000"
              customStyle={[{ fontWeight: 200 }]}
            >
              رمز عبور
            </Text>
          </Block>
          <Block>
            <Input
              type="password"
              name="password"
              onChange={(e) =>
                setState({
                  ...state,
                  authform: { ...state.authform, password: e.target.value },
                })
              }
              value={state.authform.password}
            />
          </Block>
        </Block>
        <Block>
          <Button
            onClick={handleClickLogin}
            type="primary"
            loading={state.authform.isLoggingIn}
          >
            ورود
          </Button>
        </Block>
      </Modal>
    </div>
  );
}

export default App;
