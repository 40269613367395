"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.genericSizeConstants = void 0;
var genericSizeConstants = {
  xs: 3,
  sm: 8,
  md: 15,
  lg: 22,
  xlg: 30,
  x2lg: 45,
  x3lg: 55,
  x4lg: 60,
  x5lg: 62,
  x6lg: 64,
  x7lg: 72,
  x8lg: 80,
  x9lg: 90,
  x10lg: 100,
  x11lg: 120
};
exports.genericSizeConstants = genericSizeConstants;