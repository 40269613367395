"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upScreen = exports.exactScreen = exports.downScreen = void 0;
var exactScreen = function exactScreen(screen) {
  return !screen.minWidth || !screen.maxWidth ? null : "@media (min-width: ".concat(screen.minWidth, ") and (max-width: ").concat(screen.maxWidth, ")");
};
exports.exactScreen = exactScreen;
var downScreen = function downScreen(screen) {
  return !screen.maxWidth ? null : "@media (max-width: ".concat(screen.maxWidth, ")");
};
exports.downScreen = downScreen;
var upScreen = function upScreen(screen) {
  return !screen.minWidth ? null : "@media (min-width: ".concat(screen.minWidth, ")");
};
exports.upScreen = upScreen;