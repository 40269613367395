"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  Coastomizer: true
};
Object.defineProperty(exports, "Coastomizer", {
  enumerable: true,
  get: function get() {
    return _Coastomizer["default"];
  }
});
var _Coastomizer = _interopRequireDefault(require("./lib/Coastomizer"));
var _helpers = require("./lib/helpers");
Object.keys(_helpers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _helpers[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _helpers[key];
    }
  });
});
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }