"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pixular = exports.meterize = void 0;
var pixular = function pixular(size) {
  if (typeof size === "undefined") {
    throw new TypeError('Value for argument "space" must be an instance of "String".');
  }
  return size + "px";
};
exports.pixular = pixular;
var meterize = function meterize(size, appendix) {
  if (typeof appendix !== "string") {
    throw new TypeError('Value for argument "appendix" must be an instance of "String".');
  }
  return String(size) + appendix;
};
exports.meterize = meterize;